import React from "react"

import HomePage from "src/components/home/home"

import { useAppState } from "src/state/app-state"

const IndexPage = props => {
  const { settings, branches } = useAppState()
  return <HomePage settings={settings} branches={branches} />
}

export default IndexPage
