import React from "react"
import { Link } from "gatsby"
import { css } from "linaria"

import Section from "src/components/section"
import Container from "src/components/container"
import Title from "src/components/title"
import SubTitle from "src/components/subtitle"

import { media, colors, inset, spacing } from "src/styles/members"
import introBgImage from "src/images/intro.jpg"

const introSection = css`
  min-height: auto;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &.jigBottom {
    &:after {
      background-image: linear-gradient(
          315deg,
          ${colors.black} 50%,
          transparent 0
        ),
        linear-gradient(45deg, ${colors.black} 50%, transparent 0);
    }
  }
  .list {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    ${media.md.up} {
      flex-direction: row;
    }
    margin: -0.4rem;
    .item {
      flex: 0 1 33.3333333%;
      padding: 0.4rem;
    }
  }
`

const branchLink = css`
  display: flex;
  height: 100%;
  width: 100%;
  color: ${colors.primary};
  background-color: ${colors.white};
  border-radius: ${spacing[2]};
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  font-size: ${spacing[6]};
  ${inset(spacing[4])}
  border: ${spacing[1]} solid ${colors.primary};
  ${media.md.up} {
    font-size: ${spacing[7]};
    ${inset(spacing[5])}
    border: ${spacing[1]} solid ${colors.primary};
  }
`

const IntroSection = props => {
  const { id, title, subtitle, branches } = props
  return (
    <Section id={id} className={introSection} paraImg={introBgImage} jigBottom>
      <Container>
        <Title text={title} />
        <SubTitle text={subtitle} />
        <ul className="list">
          {branches.map(branch => (
            <li className="item" key={branch.slug}>
              <Link
                to={`/${branch.slug}#menu`}
                key={branch.slug}
                className={branchLink}
              >
                {branch.title}
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </Section>
  )
}

export default IntroSection
