import React from "react"
import { css } from "linaria"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import IntroSection from "./intro-section"
import Footer from "src/components/footer"

const homePageStyle = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const footerStyle = css`
  &.jigTop:before {
    background-image: none;
  }
`

const HomePage = props => {
  const { settings, branches } = props
  return (
    <Layout cookiesConsent={settings.cookiesConsent} className={homePageStyle}>
      <SEO title={settings.title} description={settings.description} />
      <IntroSection
        id="intro"
        title={settings.title}
        subtitle={settings.description}
        branches={branches}
      />
      <Footer
        narrow={true}
        className={footerStyle}
        siteTitle={settings.title}
        googlePlayStoreUrl={settings.googlePlayStoreUrl}
        appleAppStoreUrl={settings.appleAppStoreUrl}
      />
    </Layout>
  )
}

export default HomePage
